import VueRouter from 'vue-router';

const routes = [
];


const router = new VueRouter({
	routes
});

export default router;
