const datas = [{
		"img": "images/banner/banner2.jpg",
		"url": "https://www.eimoney.com/landing/online.html?promote=678840"
	},
	{

		"img": "images/banner/banner1.jpg",
		"url": "https://www.eimoney.com/landing/online.html?promote=678840"
	}
];

export default datas;
