<template>
	<div class="root">
		<Tabs class="tabs" v-model="activeTab">
			<Tab title="全部" name="全部"></Tab>
			<Tab title="推荐" name="推荐"></Tab>
			<Tab title="秒提" name="秒提"></Tab>
			<Tab title="最新" name="最新"></Tab>
		</Tabs>
		<List class="list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
			<Cell v-for="item in list" :key="item.id">
				<AsoCell @click="onItemClick(item)" :item="item" />
			</Cell>
		</List>
	</div>
</template>

<script>
	import {
		Toast,
		List,
		Cell,
		Tab,
		Tabs
	} from 'vant';


	import asoListDatas from '@/assets/datas/aso.list.js';

	import AsoCell from '@/components/aso/cell/index.js'

	export default {
		components: {
			List,
			Cell,
			Tab,
			Tabs,
			AsoCell
		},
		data() {
			return {
				loading: false,
				finished: false,
				activeTab: '全部'
			}
		},
		computed: {
			list: function() {
				if (this.activeTab == '全部') {
					return asoListDatas;
				}
				return asoListDatas.filter((item) => {
					return item.tags.indexOf(this.activeTab) >= 0;
				});
			}
		},
		watch: {
			activeTab() {
				this.onLoad();
			}
		},
		methods: {
			onLoad() {
				this.loading = true;
				this.finished = true;
			},
			onItemClick(item) {
				Toast('点击:' + item.name);
				location.href = item.url;
			}
		}
	}
</script>
<style>
	.van-tab {
		font-size: 18px !important;
		border-bottom: 1px solid #f7f8fa;
	}
</style>
<style scoped>
	.root {
		height: 100%;
		width: 100%;
	}

	.root .van-pull-refresh {
		height: 100%;
		width: 100%;
		height: calc(100% - 90px);
		overflow: auto;
	}
</style>
