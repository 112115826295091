<template>
	<div class="bootstrap">
		<Swipe class="bootstrap-swipe" :loop="false" indicator-color="white">
			<SwipeItem>
				<img class="img" :src="img" />
			</SwipeItem>
			<SwipeItem>
				<img class="img" :src="img" />
			</SwipeItem>
			<SwipeItem>
				<img class="img" :src="img" />
			</SwipeItem>
			<SwipeItem>
				<img class="img" :src="img" />
				<Button class="start-btn" @click="onEnd">结束</Button>
			</SwipeItem>
		</Swipe>
	</div>
</template>

<script>
	import {
		Swipe,
		SwipeItem,
		Button
	} from 'vant';

	export default {
		name: 'Bootstrap',
		components: {
			Swipe,
			SwipeItem,
			Button
		},
		data() {
			return {
				value: 50,
				img: 'https://img.yzcdn.cn/vant/apple-1.jpg'
			}
		},
		methods: {
			onEnd() {
				this.$emit('end');
			}
		}
	}
</script>

<style scoped="scoped">
	.bootstrap {
		position: fixed;
		height: 100%;
		width: 100%;
		background: #42B983;
	}

	.bootstrap-swipe.van-swipe {
		width: 100%;
		height: 100%;
		display: flex;
		flex-flow: column;
	}

	.bootstrap-swipe .van-swipe-item {
		color: #fff;
		font-size: 20px;
		line-height: 150px;
		text-align: center;
		background-color: #39a9ed;
		width: 100%;
		height: 100%;
	}

	.bootstrap-swipe .van-swipe-item .img {
		height: 100%;
	}

	.bootstrap .start-btn {
		bottom: 100px;
		position: absolute;
		left: 50%;
		width: 70px;
		margin-left: calc(-35px);
	}
</style>
