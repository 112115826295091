const datas = [{
		"id": 7,
		"name": "多多红包",
		"icon": "images/duoduohongbao.jpg",
		"url": "http://drwx.duoduoaichi.cn/?r=818503334&d=20210101&en=jifenzi&t=link&m=1&bt=7",
		"tags": "推荐,秒提现",
		"btn": {
			"name": "提现",
			"color": "red"
		}
	}, {
		"id": 6,
		"name": "应用试客",
		"icon": "images/yingyongshike.png",
		"url": "https://shike.com/orochi/FKSFInviteChatIndex",
		"tags": "推荐,秒提现",
		"btn": {
			"name": "download",
			"color": "blue"
		}
	}, {
		"id": 1,
		"name": "imoney",
		"icon": "images/imoney.png",
		"url": "https://zy-train.com/landing/share.html?promote=754569",
		"tags": "任务多",
		"btn": {
			"name": "+ $50/天",
			"color": "red"
		}
	},
	{
		"id": 2,
		"name": "蝉试客",
		"icon": "images/chanshike.png",
		"url": "http://csk.limayao.com/?uid=3814364&timestamp=1609491512896",
		"tags": "推荐",
		"btn": {
			"name": "+ $30/天",
			"color": "red"
		}
	}, {
		"id": 3,
		"name": "钱脉",
		"icon": "images/qianmai.jpg",
		"url": "https://m.qianmaiap.com/?u=2134013",
		"tags": "推荐,最新",
		"btn": {
			"name": "下载",
			"color": "blue"
		}
	}, {
		"id": 4,
		"name": "蜜蜂试玩",
		"icon": "images/mifengshiwan.jpg",
		"url": "https://v2.mayixiaoka.com/rec/1060788",
		"tags": "推荐,最新",
		"btn": {
			"name": "下载",
			"color": "glup"
		}
	}, {
		"id": 5,
		"name": "豆豆趣玩",
		"icon": "images/doudouquwan.jpg",
		"url": "http://newer11982803.61pj.com/share.html?id=11982803&sf=ios&sfrom=ios",
		"tags": "推荐,最新",
		"btn": {
			"name": "下载",
			"color": "green"
		}
	},
];

export default datas;
