<template>
	<div class="home">
		<Swipe class="swipe" :autoplay="3000" indicator-color="white">
			<SwipeItem v-for="item in bannerDatas" :key="item.id">
				<img :src="item.img" />
			</SwipeItem>
		</Swipe>
		<AsoTabList />
	</div>
</template>

<script>
	import {
		Swipe,
		SwipeItem
	} from 'vant';

	import AsoTabList from '@/views/aso/list/tabList/index.js';

	import bannerDatas from '@/assets/datas/banner.js';

	export default {
		name: 'Home',
		components: {
			Swipe,
			SwipeItem,
			AsoTabList
		},
		data() {
			return {
				bannerDatas
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.home {
		width: 100%;
	}

	.home .swipe img{
		width: 100%;
		height: 180px;
	}
</style>
