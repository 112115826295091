<template>
	<div class="cell">
		<div class="icon">
			<img :src="item.icon" />
		</div>
		<div class="introduce">
			<div class="name">{{item.name}}</div>
			<div>
				<span>{{tagsMsg()}}</span>
				&nbsp;&nbsp;&nbsp;&nbsp;
				<Badge content="1秒到账" v-if="item.tags.indexOf('秒提现')>-1"/>
			</div>

		</div>
		<div class="star">

		</div>
		<div class="opt">
			<Button style="padding: 10px 16px;width: 90px;" size="small" @click="onClick" :color="item.btn.color"  :plain="true">{{item.btn.name}}</Button>
		</div>
	</div>
</template>

<script>
	import {
		Button,
		Badge
	} from 'vant';

	export default {
		props: {
			item: Object
		},
		components: {
			Button,
			Badge
		},
		data() {
			return {

			}
		},
		methods: {
			onClick() {
				this.$emit('click');
			},
			tagsMsg() {
				return this.item.tags.split(',').filter((item) => {
					return item != '秒提现';
				})[0];
			}
		}
	}
</script>

<style scoped="scoped">
	.cell {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 88px;
	}

	.cell .icon {
		width: 70px;
	}

	.cell .icon img {
		width: 66px;
		border-radius: 5px;
	}

	.cell .introduce {
		flex: auto;
		display: flex;
		flex-flow: column;
		padding-left: 10px;
		color: #999;
	}

	.cell .introduce .name {
		color: #34495e;
		font-size: 18px;
		font-weight: 800px;
		padding-bottom: 5px;
	}

	.cell .star {
		height: 10px;
		background: url(../../../assets/icons/star.png) no-repeat;
		background-size: 66px auto;
		background-position-y: bottom;
		width: 75px;
	}
	
</style>
