<template>
	<div id="app">
		<Bootstrap @end="begin" v-if="bootstrap" />
		<NavBar v-show="false" title="爱钱进" @click-left="window.history.back()" @click-right="window.history.back()" />
		<Home v-if="!bootstrap" />
	</div>
</template>

<script>
	import Bootstrap from './views/bootstrap/index.js';

	import Home from './views/home/index.js';

	import {
		NavBar
	} from 'vant';

	export default {
		name: 'App',
		components: {
			Bootstrap,
			Home,
			NavBar
		},
		data: () => {
			return {
				bootstrap: false
			}
		},
		methods: {
			begin() {
				this.bootstrap = false
			}
		}
	}
</script>

<style>
	body {
		position: fixed;
		height: 100%;
		width: 100%;
		overflow: auto;
		margin: 0;
		padding: 0;
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		position: fixed;
		height: calc(100%);
		top: 0;
		width: 100%;
		overflow: auto;
	}
</style>
